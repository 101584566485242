<template>
  <div class="coinater">
    <div class="fdleft">
      <!-- <div class="fdtitle">
        <div></div>
        <div class="fdtitlee">
          <div class="fdtfir">技术审查</div>
          <div class="fdtsec">{{ project.name }}</div>
        </div>
      </div> -->
      <div class="fdlcont">
        <div class="fdltitle">能效测评报告</div>
        <div class="fdltxt">
          <iframe
            v-if="fileType == 1"
            style="width: 100%; height: 76vh"
            :src="pdfUrl.url"
          ></iframe>
          <div v-else-if="fileType == 2">
            <div
              ref="docx_dom"
              style="width: 100%; height: 76vh; overflow-y: scroll"
            ></div>
          </div>
          <div
            v-else
            style="width: 100%; height: 600px; overflow-y: scroll"
          ></div>
        </div>
      </div>
    </div>
    <div class="fdright">
        <div class="fdtitlee">
        <div class="fdtsec">{{ project.name }}</div>
      </div>
      <div class="fdbacck">
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <div class="fdrcont">
        <div class="fdrtitle">形式审查意见</div>
        <div class="fdrtxt">
          <div  class="fdrlist">
            <div class="fdrlcont">
              {{ baogao_check.idea }}
            </div>

            <div class="fdrtime">
              {{ baogao_check.created_at | formatDate("yyyy-MM-dd") }}
            </div>
          </div>
        </div>
        <div class="fdrtitle">基础项审查意见</div>
        <div class="fdrtxt">
          <div v-for="item in shencha1" :key="item.id" class="fdrlist">
            <div class="fdrlcont">
              {{ item.idea }}
            </div>

            <div class="fdrtime">
              {{ item.created_at | formatDate("yyyy-MM-dd") }}
            </div>
          </div>
        </div>
        <div class="fdrtitle">规定项审查意见</div>
        <div class="fdrtxt">
          <el-input
            v-model="opinion2.idea"
            placeholder="请输入内容"
            style="border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdrtitle">选择项审查意见</div>
        <div class="fdrtxt">
          <el-input
            v-model="opinion3.idea"
            placeholder="请输入内容"
            style="border: 1px solid #dcdfe6"
            type="textarea"
          >
          </el-input>
        </div>
        <div class="fdradio">
          <el-radio-group :disabled="!is_expert" v-model="pass">
            <el-radio :label="1">达标</el-radio>
            <el-radio :label="2">未达标</el-radio>
            <el-radio :label="3">待定</el-radio>
          </el-radio-group>
        </div>
        <div class="fdrbtn">
          <!-- <el-button type="primary" :disabled="!is_expert" @click="pre_page">上一页</el-button> -->
          <el-button type="primary" :disabled="!is_expert" @click="check"
            >保存</el-button
          >
          <el-button type="primary" :disabled="!is_expert" @click="next_page"
            >生成意见表</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { projectTableInfo, skillSave } from "../../../../api/project";
import axios from "axios";
let docx = require("docx-preview");
export default {
   filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      opinion1: {
        type: 7,
      },
      opinion2: {
        type: 8,
      },
      opinion3: {
        type: 9,
      },
      pdfShow: false,
      pdfUrl: {},
      numPages: null, // pdf 总页数
      idea: "",
      pass: "",
      project_id: "",
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],

      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],

      jizu3: [],
      jizu4: [],
      fileType: 1,
      checks: [],
      shencha1:[],

      is_expert: false,
    };
  },
  mounted() {
    //判断用户是否是专家
    let role = this.$store.state.role;
    if (role && role.indexOf(2) != -1) {
      this.is_expert = true;
    }
    this.project_id = this.$route.query.project_id;
    this.getInfo();
    let form = this.$route.query.check;
    if (form && form != "") {
      form = JSON.parse(form);
      this.idea = form.idea;
      this.pass = form.pass;
    }
  },
  methods: {
    pre_page() {
      this.$router.go(-1);
    },
    next_page() {
      this.$router.push({
        path: "/formal/ipendTable?project_id=" + this.project_id,
      });
    },
    check() {
      let params = {
        pass: this.pass,
        project_id: this.project_id,
        token: this.$store.state.token,
      };
      let data = [];
      data.push(this.opinion1);
      data.push(this.opinion2);
      data.push(this.opinion3);
      params.data = data;
      let that = this;
      skillSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        that.checks = res.data.checks;
        that.shencha1 =  that.checks
          .map((item) => {
            if (item.type == 7) {
              return item;
            }
          })
          .filter((d) => d);

        for (let i = 0; i < that.checks.length; i++) {
          if (that.checks[i].type == 1) {
            that.shenbao_check = that.checks[i];
          } else if (that.checks[i].type == 2) {
            that.nengxiao_check = that.checks[i];
          } else if (that.checks[i].type == 3) {
            that.baogao_check = that.checks[i];
          } else if (that.checks[i].type == 4) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 5) {
            that.fujian_check = that.checks[i];
          } else if (that.checks[i].type == 6) {
            that.zhibiao_check = that.checks[i];
          } else if (that.checks[i].type == 7) {
            that.opinion1 = that.checks[i];
            that.pass = that.checks[i].pass;
          } else if (that.checks[i].type == 8) {
            that.opinion2 = that.checks[i];
          } else if (that.checks[i].type == 9) {
            that.opinion3 = that.checks[i];
          }
        }

        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.pdfUrl = that.project.files.find(function (item) {
          return item.type == 3;
        });
        //判断文件类型，1为pdf 2为word
        if (that.pdfUrl && that.pdfUrl.url && that.pdfUrl.url != "") {
          let file_type = that.pdfUrl.url.split(".");
          if (file_type[file_type.length - 1] == "pdf") {
            that.fileType = 1;
          } else {
            that.fileType = 2;
            let file_name_arr = that.pdfUrl.url.split("/");
            let file_name = file_name_arr[file_name_arr.length - 1];
            this.$nextTick(function () {
              axios({
                method: "get",
                responseType: "blob", // 设置响应文件格式
                url: "/files/" + file_name,
              }).then(({ data }) => {
                docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
              });
            });
          }
        } else {
          that.fileType = 3;
        }
      });
    },
    goBack() {
      if (this.is_expert) {
        this.$router.push({
          path: "./exportIpendDeatil?id=" + this.project_id,
        });
      } else {
        this.$router.push({
          path: "/technology/techInformation?id=" + this.project_id,
        });
      }
      // this.$router.back()
    },
  },
};
</script>
<style scoped>
/deep/.el-textarea > textarea {
  height: 150px;
}
/deep/.table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style lang="scss" scoped>
.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.coinater {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .fdleft {
    width: 74%;

    .fdtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 96px;

      .fdtitlee {
        display: flex;
        flex-direction: column;

        .fdtfir {
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #333333;
          margin-bottom: 8px;
        }

        .fdtsec {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .fdlcont {
      background-color: #ffffff;
      padding: 6px;
      box-shadow: 0px 8px 9px 1px rgba(149, 149, 149, 0.16);
      border-radius: 6px;

      .fdltitle {
        margin-right: 35px;
        height: 60px;
        line-height: 60px;
        padding-left: 23px;
        background: #eeeeee;
        border-radius: 4px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
      }

      .fdltxt {
        margin: 17px;
        height: 76vh;
      }
    }
  }

  .fdright {
    width: 25%;
    height: 800px;
.fdtsec {
    display: flex;
      flex-direction: row-reverse;
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #666666;
        }
    .fdbacck {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 96px;
    }

    .fdrcont {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 30px 20px;

      .fdrtitle {
        font-size: 20px;
        font-family: Adobe Heiti Std;
        color: #333333;
        line-height: 23px;
        margin: 10px;
      }
      .fdrtxt {
        // widows: 98%;
        height: 110px;
        overflow-y: scroll;
        border: 1px solid #3086fb;
        .fdrlist {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px dashed rgb(220, 223, 230);
          margin: 10px;
          padding: 3px 0;
          .fdrlcont {
            width: 60%;
          }
        }
      }
      .fdrtxt::-webkit-scrollbar {
        display: none;
      }
      .fdradio {
        margin: 20px 0;
        text-align: center;
      }

      .fdrbtn {
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}
.engerytable input {
  background-color: white;
}
</style>
